<template>
  <div class="show-item">
    <p class="show-item--label">
      {{ label }}
      <info-tooltip
        v-if="help"
        :tooltip-content="help"
      />
    </p>
    <div class="show-item--value">
      <slot name="value">
        <p>
          {{ value }}
        </p>
      </slot>
    </div>
  </div>
</template>

<script>
import InfoTooltip from './InfoTooltip.vue';

export default {
  name: 'ShowItem',
  components: {
    InfoTooltip,
  },
  props: {
    label: { type: String, default: '' },
    value: { type: [String, Number], default: '' },
    help: { type: String, default: '' },
  },
};
</script>

<style lang="scss">
.show-item {
  margin-bottom: 22px;

  &--label {
    color: $--color-primary;
    font-size: $--form-label-font-size;
    line-height: 1;
  }
  &--value {
    font-size: $--font-size-base;
    line-height: $--font-line-height-secondary;
    padding: 12px 0;
  }
}
</style>
