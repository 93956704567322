<template>
  <ContentWrapper
    v-loading="loading"
    :title="offer.docNumber"
    :span="20"
    class="show-offer-view"
  >
    <el-row :gutter="20">
      <el-col
        v-if="userPermissions && userPermissions.includes('admin')"
        :span="6"
      >
        <ShowItem
          :label="$t('offer.ondaOfferId')"
          :value="offer.ondaOfferId"
        />
      </el-col>

      <el-col
        v-if="userPermissions && userPermissions.includes('admin')"
        :span="6"
      >
        <ShowItem :label="$t('offer.customer')">
          <p
            v-for="item in customers"
            slot="value"
            :key="item.name"
          >
            {{ item.name }}
          </p>
        </ShowItem>
      </el-col>

      <el-col :span="6">
        <ShowItem
          :label="$t('offer.docNumber')"
          :value="offer.docNumber"
        />
      </el-col>

      <el-col :span="6">
        <ShowItem
          :label="$t('offer.issuedAt')"
          :value="offer.issuedAtLocal | date('DD MMMM YYYY')"
        />
      </el-col>

      <el-col :span="6">
        <ShowItem
          :label="$t('offer.expirationDate')"
          :value="offer.expirationDate | date('DD MMMM YYYY')"
        />
      </el-col>

      <el-col
        v-if="userPermissions && userPermissions.includes('admin')"
        :span="6"
      >
        <ShowItem :label="$t('offer.status')">
          <p
            v-for="item in offerStatuses"
            slot="value"
            :key="item.label"
          >
            {{ item.value === offer.status ? item.label : null }}
          </p>
        </ShowItem>
      </el-col>

      <el-col
        v-if="userPermissions && userPermissions.includes('admin')"
        :span="6"
      >
        <ShowItem
          :label="$t('offer.ourReference')"
          :value="offer.ourReference"
        />
      </el-col>

      <el-col :span="6">
        <ShowItem
          :label="$t('offer.yourReference')"
          :value="offer.yourReference"
        />
      </el-col>

      <el-col :span="24">
        <ShowItem
          :label="$t('offer.comment')"
          :value="offer.comment"
        />
      </el-col>

      <el-col :span="6">
        <ShowItem
          :label="$t('offer.revisionNumber')"
          :value="offer.revisionNumber"
        />
      </el-col>

      <el-col
        v-if="userPermissions && userPermissions.includes('admin')"
        :span="6"
      >
        <ShowItem
          :label="$t('offer.revisionDate')"
          :value="offer.revisionDate | date('DD MMMM YYYY')"
        />
      </el-col>

      <el-col
        v-if="userPermissions && userPermissions.includes('admin')"
        :span="6"
      >
        <ShowItem :label="$t('offer.downloadPdf')">
          <a
            v-if="file"
            slot="value"
            href="#"
            @click.prevent="downloadPdf"
          >{{ $t('offer.downloadPdf') }}</a>
        </ShowItem>
      </el-col>
    </el-row>
    <TableWrapper
      :table-columns="offerItemsColumns"
      table-key="offerItems"
    />
    <ContentButtons>
      <el-row
        type="flex"
        justify="end"
      >
        <el-col :span="24">
          <router-link
            :to="{ name: 'offersIndex' }"
            tag="el-button"
            type="secondary"
          >
            {{ $t('global.backToList') }}
          </router-link>
        </el-col>
      </el-row>
    </ContentButtons>
  </ContentWrapper>
</template>

<script>
import fileSaver from 'file-saver';
import { mapGetters, mapActions } from 'vuex';
import ShowItem from '@/components/ShowItem.vue';
import TableWrapper from '@/components/TableWrapper.vue';
import ContentWrapper from '@/components/ContentWrapper.vue';
import ContentButtons from '@/components/ContentButtons.vue';
import { offersApi, customersApi } from '../../api';
import { offerItemsColumns } from '../../config';

export default {
  name: 'ShowOffer',
  components: {
    TableWrapper,
    ContentWrapper,
    ContentButtons,
    ShowItem,
  },
  data() {
    return {
      offerItemsColumns,
      offerItems: [],
      offerStatuses: [
        { value: 'I', label: 'Nuovo inserimento' },
        { value: 'V', label: 'Variazione' },
      ],
      file: null,
      loading: false,
      customers: [],
      offer: {
        id: 0,
        ondaOfferId: 0,
        registryId: 0,
        customerId: '',
        docNumber: '',
        issuedAtLocal: '',
        ourReference: '',
        yourReference: '',
        comment: '',
        revisionNumber: null,
        revisionDate: '',
        pdfFileId: '',
      },
    };
  },
  computed: {
    ...mapGetters([
      'filtersStatus',
      'tableFilters',
      'userPermissions',
    ]),
  },
  async created() {
    this.resetTable('offerItems');
    this.parseQueryStringStatus(this.$route.query);
    this.offer = await this.getOffer();
    await this.getCustomer();
    await this.getOfferPdf();
    await this.tableRows();
  },
  beforeRouteUpdate(to, from, next) {
    this.parseQueryStringStatus(to.query);
    this.tableRows();
    next();
  },
  methods: {
    ...mapActions([
      'updateTableRows',
      'updateTableCount',
      'resetTable',
      'parseQueryStringStatus',
      'setLoggedUser',
    ]),
    async getOfferPdf() {
      try {
        this.loading = true;
        const file = await offersApi.getOfferPdf(this.offer.id);
        if (file.data) {
          this.file = file.data;
        }
        this.loading = false;
        return file;
      } catch (error) {
        this.loading = false;
        return error;
      }
    },
    downloadPdf() {
      if (this.file) {
        return fileSaver.saveAs(this.file, 'filename.pdf');
      }
      return false;
    },
    async getCustomer() {
      try {
        this.loading = true;
        const customer = this.offer.customerId
          ? await customersApi.findCustomerById(this.offer.customerId) : {};
        if (customer.data.id) {
          this.customers.push(customer.data);
        }
        this.loading = false;
        return customer;
      } catch (error) {
        this.loading = false;
        return error;
      }
    },
    async getOffer() {
      try {
        this.loading = true;
        const offer = await offersApi.show(this.$route.params.id);
        this.loading = false;
        return offer.data;
      } catch (error) {
        this.loading = false;
        return error;
      }
    },
    async tableRows() {
      try {
        const offerItemsIndexResponse = await offersApi.itemsIndex(this.offer.id,
          this.tableFilters('offerItems'),
          this.filtersStatus.offerItems);
        const offerItemsCountResponse = await offersApi.itemsCount(this.offer.id,
          this.filtersStatus.offerItems);
        this.updateTableRows({ tableKey: 'offerItems', rows: offerItemsIndexResponse.data });
        this.updateTableCount({ tableKey: 'offerItems', count: offerItemsCountResponse.data.length });
      } catch (error) {
        throw error;
      }
    },
  },
};
</script>
